<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('students')" @filter-div-status="datatable.filterStatus = $event" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('students')" @filter-div-status="datatable.filterStatus = $event" />
            </template>
            <datatable-filter v-show="datatable.filterStatus" :filterButtonClass="'mt-1'" @filter="filter"
                @filterClear="filterClearAndGetRow" :exportExcel="checkPermission('studentprogram_excelexport')"
                @exportExcel="exportExcel">
                <div>
                    <b-tabs content-class="py-5 position-relative" class="line-tabs">
                        <div class="tabs-bottom-line"></div>
                        <!-- Tab 1: Kişi Bilgileri -->
                        <b-tab active>
                            <template #title>
                                <span v-html="computeTab4Name"></span>
                            </template>
                            <b-row>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('student_number')">
                                        <b-form-input type="number" v-model="datatable.queryParams.filter.student_number"
                                                      v-check-min-max-value></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('national_number')">
                                        <b-form-input type="number" v-model="datatable.queryParams.filter.national_id"
                                                      v-check-min-max-value></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('name')">
                                        <b-form-input v-model="datatable.queryParams.filter.name"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('surname')">
                                        <b-form-input v-model="datatable.queryParams.filter.surname"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('gender')">
                                        <gender-selectbox v-model="datatable.queryParams.filter.gender" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('student_group')">
                                        <multi-selectbox v-model="datatable.queryParams.filter.student_group"
                                                         :options="studentGroupOptions"
                                                         :multiple="false" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('nationality')">
                                        <country-selectbox v-model="datatable.queryParams.filter.nationality_code"
                                                           valueType="code"></country-selectbox>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('passport_number')">
                                        <b-form-input v-model="datatable.queryParams.filter.passport_number"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('year_of_birth')">
                                        <b-form-input type="number" v-model="datatable.queryParams.filter.birth_year"
                                                      ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('mother_name')">
                                        <b-form-input v-model="datatable.queryParams.filter.mother_name"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('father_name')">
                                        <b-form-input v-model="datatable.queryParams.filter.father_name"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('military_status')">
                                        <parameter-selectbox v-model="datatable.queryParams.filter.military_status"
                                                             code="military_statuses"></parameter-selectbox>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-tab>

                        <!--Tab 2: Kabul Verileri-->
                        <b-tab>
                            <template #title>
                                <span v-html="computeTab1Name"></span>
                            </template>
                            <b-row>
                                <b-col cols="12" md="6" lg="4">
                                    <b-form-group :label="$t('faculty')">
                                        <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code" />
                                    </b-form-group>
                                    <b-form-group :label="$t('department')">
                                        <department-selectbox v-model="datatable.queryParams.filter.department_code"
                                            :faculty_code="datatable.queryParams.filter.faculty_code"></department-selectbox>
                                    </b-form-group>
                                    <b-form-group :label="$t('program')">
                                        <program-selectbox v-model="datatable.queryParams.filter.program_code"
                                            :faculty_code="datatable.queryParams.filter.faculty_code"
                                            :department_code="datatable.queryParams.filter.department_code" />
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="6" lg="4">
                                    <b-form-group :label="$t('registration_type_1')">
                                        <parameter-selectbox v-model="datatable.queryParams.filter.registration_type"
                                            code="registration_types"></parameter-selectbox>
                                    </b-form-group>
                                    <b-form-group :label="$t('mode_of_study')">
                                        <parameter-selectbox v-model="datatable.queryParams.filter.mode_of_study"
                                            code="mode_of_studies"></parameter-selectbox>
                                    </b-form-group>

                                    <b-form-group :label="$t('with_thesis') + ' / ' + $t('without_thesis')">
                                        <b-form-select v-model="datatable.queryParams.filter.with_thesis" :options="[
                                            { value: null, text: $t('select') },
                                            { value: true, text: $t('with_thesis') },
                                            { value: false, text: $t('without_thesis') }
                                        ]">
                                        </b-form-select>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="6" lg="4">
                                    <b-form-group :label="$t('campus')">
                                        <campus-selectbox v-model="datatable.queryParams.filter.campus_name" />
                                    </b-form-group>
                                    <b-form-group :label="$t('program_language')">
                                        <language-selectbox v-model="datatable.queryParams.filter.program_language" />
                                    </b-form-group>
                                    <b-form-group :label="$t('class')">
                                        <parameter-selectbox v-model="datatable.queryParams.filter.class" code="classes" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="6" lg="4">
                                    <b-form-group :label="$t('major_types')">
                                        <parameter-selectbox v-model="datatable.queryParams.filter.major_type"
                                            code="major_types" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="6" lg="4">
                                    <b-form-group :label="$t('prep_status')">
                                        <parameter-selectbox v-model="datatable.queryParams.filter.prep_status"
                                            code="prep_statuses" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="6" lg="4">
                                    <b-form-group :label="$t('level')">
                                        <parameter-selectbox v-model="datatable.queryParams.filter.program_level"
                                            code="program_levels"></parameter-selectbox>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-tab>

                        <!--Tab 3: Kesin Kayıt Bilgileri-->
                        <b-tab>
                            <template #title>
                                <span v-html="computeTab2Name"></span>
                            </template>
                            <b-row>
                                <b-col cols="12" sm="6" md="4">
                                    <h5>{{ $t('registration_information') }}</h5>
                                    <div class="border rounded p-4">
                                        <b-form-group :label="$t('registration_academic_year')">
                                            <academic-years-selectbox
                                                v-model="datatable.queryParams.filter.registration_academic_year"></academic-years-selectbox>
                                        </b-form-group>
                                        <b-form-group :label="$t('registration_semester')">
                                            <parameter-selectbox
                                                v-model="datatable.queryParams.filter.registration_semester" code="semester"
                                                sort="code"></parameter-selectbox>
                                        </b-form-group>
                                        <b-form-group :label="$t('registration_date_between')" class="mb-0">
                                            <v-date-picker v-model="datatable.queryParams.filter.registration_date_between"
                                                is-range>
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <div class="d-flex align-items-center">
                                                        <div class="flex-grow-1">
                                                            <b-form-group class="m-0 mr-1">
                                                                <input :value="inputValue.start" v-on="inputEvents.start"
                                                                    :placeholder="$t('start_date')" class="form-control" />
                                                            </b-form-group>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <b-form-group class="m-0 ml-1">
                                                                <input :value="inputValue.end" v-on="inputEvents.end"
                                                                    :placeholder="$t('end_date')" class="form-control" />
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="6" md="4">
                                    <h5>{{ $t('graduation_information') }}</h5>
                                    <div class="border rounded p-4">
                                        <b-form-group :label="$t('graduation_academic_year')">
                                            <academic-years-selectbox
                                                v-model="datatable.queryParams.filter.graduation_academic_year"></academic-years-selectbox>
                                        </b-form-group>
                                        <b-form-group :label="$t('graduation_semester')">
                                            <parameter-selectbox v-model="datatable.queryParams.filter.graduation_semester"
                                                code="semester" sort="code"></parameter-selectbox>
                                        </b-form-group>
                                        <b-form-group :label="$t('graduation_date_between')" class="mb-0">
                                            <v-date-picker v-model="datatable.queryParams.filter.graduation_date_between"
                                                is-range>
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <div class="d-flex align-items-center">
                                                        <div class="flex-grow-1">
                                                            <b-form-group class="m-0 mr-1">
                                                                <input :value="inputValue.start" v-on="inputEvents.start"
                                                                    :placeholder="$t('start_date')" class="form-control" />
                                                            </b-form-group>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <b-form-group class="m-0 ml-1">
                                                                <input :value="inputValue.end" v-on="inputEvents.end"
                                                                    :placeholder="$t('end_date')" class="form-control" />
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="6" md="4">
                                    <h5>{{ $t('leaving_information') }}</h5>
                                    <div class="border rounded p-4">
                                        <b-form-group :label="$t('leaving_academic_year')">
                                            <academic-years-selectbox
                                                v-model="datatable.queryParams.filter.leaving_academic_year"></academic-years-selectbox>
                                        </b-form-group>
                                        <b-form-group :label="$t('leaving_semester')">
                                            <parameter-selectbox v-model="datatable.queryParams.filter.leaving_semester"
                                                code="semester" sort="code"></parameter-selectbox>
                                        </b-form-group>
                                        <b-form-group :label="$t('leaving_date_between')">
                                            <v-date-picker v-model="datatable.queryParams.filter.leaving_date_between"
                                                is-range>
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <div class="d-flex align-items-center">
                                                        <div class="flex-grow-1">
                                                            <b-form-group class="m-0 mr-1">
                                                                <input :value="inputValue.start" v-on="inputEvents.start"
                                                                    :placeholder="$t('start_date')" class="form-control" />
                                                            </b-form-group>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <b-form-group class="m-0 ml-1">
                                                                <input :value="inputValue.end" v-on="inputEvents.end"
                                                                    :placeholder="$t('end_date')" class="form-control" />
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                        <b-form-group :label="$t('reason_for_leaving')">
                                            <parameter-selectbox
                                                v-model="datatable.queryParams.filter.leaving_reason"
                                                code="leaving_reasons"></parameter-selectbox>
                                        </b-form-group>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-tab>

                        <!-- Tab 4: Dönem / Kayıt Bilgileri -->
                        <b-tab>
                            <template #title>
                                <span v-html="computeTab3Name"></span>
                            </template>
                            <b-row>
                                <b-col cols="12" md="4">
                                    <b-form-group :label="$t('student_status')">
                                        <parameter-selectbox v-model="datatable.queryParams.filter.student_status"
                                            code="student_statuses"></parameter-selectbox>
                                    </b-form-group>
                                    <b-form-group :label="$t('semester_status')">
                                        <parameter-selectbox v-model="datatable.queryParams.filter.active_semester_status"
                                            code="semester_statuses"></parameter-selectbox>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <b-form-group :label="$t('number_of_semesters')">
                                        <period-selectbox
                                            v-model="datatable.queryParams.filter.number_of_semesters"></period-selectbox>
                                    </b-form-group>
                                    <b-form-group :label="$t('yoksis') + '-' + $t('number_of_semesters')">
                                        <period-selectbox
                                            v-model="datatable.queryParams.filter.yoksis_number_of_semesters"></period-selectbox>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <b-form-group :label="$t('gna_not_range')">
                                        <gno-range :gno_range_start.sync="datatable.queryParams.filter.gno_range_start"
                                            :gno_range_end.sync="datatable.queryParams.filter.gno_range_end" />
                                    </b-form-group>
                                    <b-form-group :label="'Irregular'">
                                        <yes-no-selectbox
                                            v-model="datatable.queryParams.filter.irregular"></yes-no-selectbox>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <b-form-group :label="$t('success_status')">
                                        <parameter-selectbox
                                            v-model="datatable.queryParams.filter.active_semester_success_status"
                                            code="success_statuses"></parameter-selectbox>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <b-form-group :label="$t('advisor')">
                                        <staff-auto-complete
                                            v-model="datatable.queryParams.filter.advisor_id" />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-tab>

                        <!-- Tab 5: Ödeme ve Burs Bilgileri -->
                        <b-tab>
                            <template #title>
                                <span v-html="computeTab5Name"></span>
                            </template>
                            <b-row>
                                <b-col cols="12" md="4" lg="3">
                                    <b-form-group :label="$t('payment_status')">
                                        <parameter-selectbox v-model="datatable.queryParams.filter.payment_status"
                                            code="payment_statuses"></parameter-selectbox>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3">
                                    <b-form-group :label="$t('settlement_scholarship')">
                                        <b-input-group>
                                            <template #append>
                                                <b-input-group-text class="font-weight-bolder h-40">%
                                                </b-input-group-text>
                                            </template>
                                            <b-form-input type="number"
                                                v-model="datatable.queryParams.filter.scholarship_rate" :min="0" :max="100"
                                                v-check-min-max-value>
                                            </b-form-input>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-tab>
                    </b-tabs>
                </div>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable" />
            <CommonModal ref="showDocumentModal" size="lg" @bHideModalHeaderClose="closedDocumentModal" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('documents').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <div class="mb-2 border rounded p-2 text-center bg-dark text-white">
                        {{ user.student_number }} - {{ user.name }} {{ user.surname }}
                    </div>
                    <b-row>
                        <template v-for="(item, itemKey) in computeDocuments">
                            <b-col cols="6" :key="'document' + itemKey" v-if="checkPermission(item.permission)">
                                <b-dropdown class="mb-2" block split split-variant="outline-dark" variant="dark" right
                                    :text="item.text.toUpper()">
                                    <b-dropdown-item @click="item.callback(lang)" v-for="(lang, langKey) in item.language"
                                        :key="'lang' + langKey">
                                        {{ $t(lang) }}
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-col>
                        </template>
                    </b-row>
                </template>
            </CommonModal>
            <CommonModal ref="postponementCertModal" size="md">
                <template v-slot:CommonModalTitle>
                    {{ $t(postponementForm.military_dispatch_status).toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="postponementForm">
                            <b-row v-if="postponementForm.military_dispatch_status == 'delay_1'">
                                <b-col md="12">
                                    <ValidationProvider name="military_branch" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('military_branch_to')">
                                            <b-form-input v-model="postponementForm.military_branch"
                                                :class="errors[0] ? 'is-invalid' : ''">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="graduate_start_date" rules="required"
                                        v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('undergraduate_start_date')">
                                            <v-date-picker v-model="postponementForm.graduate_start_date"
                                                :locale="$i18n.locale" is-expanded :masks="masks"
                                                :popover="{ 'visibility': 'click' }">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input :readonly="true" :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <b-button variant="outline-secondary" class="btn-40" disabled>
                                                                <i class="ri-calendar-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                            v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="date_of_postponement" rules="required"
                                        v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('delay_do_date')">
                                            <v-date-picker v-model="postponementForm.date_of_postponement"
                                                :locale="$i18n.locale" is-expanded :masks="masks"
                                                :popover="{ 'visibility': 'click' }">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input :readonly="true" :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <b-button variant="outline-secondary" class="btn-40" disabled>
                                                                <i class="ri-calendar-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                            v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="reason_for_extension" rules="required"
                                        v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('reason_for_postponement')">
                                            <b-form-input v-model="postponementForm.reason_for_extension"
                                                :class="errors[0] ? 'is-invalid' : ''">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="explanation" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('explanation')">
                                            <b-form-textarea rows="4"
                                                v-model="postponementForm.explanation"></b-form-textarea>
                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row v-if="postponementForm.military_dispatch_status == 'extension'">
                                <b-col md="12">
                                    <ValidationProvider name="military_branch" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('military_branch_to')">
                                            <b-form-input v-model="postponementForm.military_branch"
                                                :class="errors[0] ? 'is-invalid' : ''">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="graduate_start_date" rules="required"
                                        v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('undergraduate_start_date')">
                                            <v-date-picker v-model="postponementForm.graduate_start_date"
                                                :locale="$i18n.locale" is-expanded :masks="masks"
                                                :popover="{ 'visibility': 'click' }">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input :readonly="true" :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <b-button variant="outline-secondary" class="btn-40" disabled>
                                                                <i class="ri-calendar-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                            v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="graduate_leave_date" rules="required"
                                        v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('undergraduate_leave_date')">
                                            <v-date-picker v-model="postponementForm.graduate_leave_date"
                                                :locale="$i18n.locale" is-expanded :masks="masks"
                                                :popover="{ 'visibility': 'click' }">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input :readonly="true" :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <b-button variant="outline-secondary" class="btn-40" disabled>
                                                                <i class="ri-calendar-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                            v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="date_of_postponement" rules="required"
                                        v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('delay_do_date')">
                                            <v-date-picker v-model="postponementForm.date_of_postponement"
                                                :locale="$i18n.locale" is-expanded :masks="masks"
                                                :popover="{ 'visibility': 'click' }">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input :readonly="true" :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <b-button variant="outline-secondary" class="btn-40" disabled>
                                                                <i class="ri-calendar-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                            v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="reason_for_cancel" rules="required"
                                        v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('cancel_reason_graduation')">
                                            <b-form-input v-model="postponementForm.reason_for_cancel"
                                                :class="errors[0] ? 'is-invalid' : ''">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="reason_for_extension" rules="required"
                                        v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('reason_of_extension')">
                                            <b-form-input v-model="postponementForm.reason_for_extension"
                                                :class="errors[0] ? 'is-invalid' : ''">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="explanation" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('explanation')">
                                            <b-form-textarea rows="4"
                                                v-model="postponementForm.explanation"></b-form-textarea>
                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row v-if="postponementForm.military_dispatch_status == 'cancel'">
                                <b-col md="12">
                                    <ValidationProvider name="military_branch" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('military_branch_to')">
                                            <b-form-input v-model="postponementForm.military_branch"
                                                :class="errors[0] ? 'is-invalid' : ''">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="graduate_start_date" rules="required"
                                        v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('undergraduate_start_date')">
                                            <v-date-picker v-model="postponementForm.graduate_start_date"
                                                :locale="$i18n.locale" is-expanded :masks="masks"
                                                :popover="{ 'visibility': 'click' }">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input :readonly="true" :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <b-button variant="outline-secondary" class="btn-40" disabled>
                                                                <i class="ri-calendar-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                            v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="graduate_leave_date" rules="required"
                                        v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('undergraduate_leave_date')">
                                            <v-date-picker v-model="postponementForm.graduate_leave_date"
                                                :locale="$i18n.locale" is-expanded :masks="masks"
                                                :popover="{ 'visibility': 'click' }">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input :readonly="true" :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <b-button variant="outline-secondary" class="btn-40" disabled>
                                                                <i class="ri-calendar-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                            v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="date_of_postponement" rules="required"
                                        v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('delay_do_date')">
                                            <v-date-picker v-model="postponementForm.date_of_postponement"
                                                :locale="$i18n.locale" is-expanded :masks="masks"
                                                :popover="{ 'visibility': 'click' }">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input :readonly="true" :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <b-button variant="outline-secondary" class="btn-40" disabled>
                                                                <i class="ri-calendar-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                            v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="reason_for_cancel" rules="required"
                                        v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('cancel_reason_graduation')">
                                            <b-form-input v-model="postponementForm.reason_for_cancel"
                                                :class="errors[0] ? 'is-invalid' : ''">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="explanation" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('explanation')">
                                            <b-form-textarea rows="4"
                                                v-model="postponementForm.explanation"></b-form-textarea>
                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="12" class="d-flex justify-content-between">
                                    <b-button variant="primary" @click="postponementAction">{{ $t('print') }}</b-button>
                                    <b-button variant="outline-dark" @click="closePostponementModal">{{
                                        $t('cancel')
                                    }}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </ValidationObserver>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';

// Components
import AcademicYearsSelectbox from '../../../components/interactive-fields/AcademicYearsSelectbox';
import CampusSelectbox from '@/components/interactive-fields/CampusSelectbox';
import CountrySelectbox from '@/components/interactive-fields/CountrySelectbox';
import DepartmentSelectbox from '@/components/interactive-fields/DepartmentSelectbox';
import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox';
import GenderSelectbox from '@/components/interactive-fields/GenderSelectbox';
import LanguageSelectbox from '@/components/interactive-fields/LanguageSelectbox';
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
import PeriodSelectbox from '../../../components/interactive-fields/PeriodSelectbox';
import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox';
import SemestersSelectbox from '../../../components/interactive-fields/SemestersSelectbox';
import ThesisSelectbox from '@/components/interactive-fields/ThesisSelectbox';
import YesNoSelectbox from '@/components/interactive-fields/YesNoSelectbox';
import CommonModal from '@/components/elements/CommonModal';
import GnoRange from '@/components/elements/GnoRange';
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete";

// Services
import StudentProgramService from '@/services/StudentProgramService';
import StudentDocumentService from '@/services/StudentDocumentService';
import PrepStudentService from '@/services/PrepStudentService';

// Data
import documentData from '@/modules/students/data/Documents'

// Other
import qs from 'qs'
import { EventBus } from "@/main";
import Base from "@/plugins/Base";


export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,

        AcademicYearsSelectbox,
        CampusSelectbox,
        CountrySelectbox,
        DepartmentSelectbox,
        FacultySelectbox,
        GenderSelectbox,
        LanguageSelectbox,
        ParameterSelectbox,
        PeriodSelectbox,
        ProgramSelectbox,
        SemestersSelectbox,
        ThesisSelectbox,
        YesNoSelectbox,
        GnoRange,
        StaffAutoComplete,

        CommonModal
    },
    metaInfo() {
        return {
            title: this.$t('students')
        }
    },
    computed: {
        computeTab1Name() {
            let count = 0;
            let filters = this.datatable.queryParams.filter;

            if (this.isNotNull(filters.faculty_code)) count++;
            if (this.isNotNull(filters.department_code)) count++;
            if (this.isNotNull(filters.program_code)) count++;
            if (this.isNotNull(filters.registration_type)) count++;
            if (this.isNotNull(filters.mode_of_study)) count++;
            if (this.isNotNull(filters.with_thesis)) count++;
            if (this.isNotNull(filters.campus_name)) count++;
            if (this.isNotNull(filters.program_language)) count++;
            if (this.isNotNull(filters.major_type)) count++;
            if (this.isNotNull(filters.prep_status)) count++;
            if (this.isNotNull(filters.program_level)) count++;

            if (count) {
                let filterButton = '<span class="badge badge-primary ml-1">' + count + '</span>';
                return this.$t('acceptance_data') + filterButton
            }
            return this.$t('acceptance_data');
        },
        computeTab2Name() {
            let count = 0;
            let filters = this.datatable.queryParams.filter;

            if (this.isNotNull(filters.registration_academic_year)) count++;
            if (this.isNotNull(filters.registration_semester)) count++;
            if (this.isNotNull(filters.registration_date_between)) count++;
            if (this.isNotNull(filters.graduation_academic_year)) count++;
            if (this.isNotNull(filters.graduation_semester)) count++;
            if (this.isNotNull(filters.graduation_date_between)) count++;
            if (this.isNotNull(filters.leaving_academic_year)) count++;
            if (this.isNotNull(filters.leaving_semester)) count++;
            if (this.isNotNull(filters.leaving_reason)) count++;
            if (this.isNotNull(filters.leaving_date_between)) count++;

            if (count) {
                let filterButton = '<span class="badge badge-primary ml-1">' + count + '</span>';
                return this.$t('definitive_registration_information') + filterButton
            }
            return this.$t('definitive_registration_information');
        },
        computeTab3Name() {
            let count = 0;
            let filters = this.datatable.queryParams.filter;

            if (this.isNotNull(filters.student_status)) count++;
            if (this.isNotNull(filters.active_semester_status)) count++;
            if (this.isNotNull(filters.number_of_semesters)) count++;
            if (this.isNotNull(filters.yoksis_number_of_semesters)) count++;
            if (this.isNotNull(filters.gno_range_start)) count++;
            if (this.isNotNull(filters.irregular)) count++;
            if (this.isNotNull(filters.active_semester_success_status)) count++;

            if (count) {
                let filterButton = '<span class="badge badge-primary ml-1">' + count + '</span>';
                return this.$t('semester_registration_information') + filterButton
            }
            return this.$t('semester_registration_information');
        },
        computeTab4Name() {
            let count = 0;
            let filters = this.datatable.queryParams.filter;

            if (this.isNotNull(filters.student_number)) count++;
            if (this.isNotNull(filters.national_id)) count++;
            if (this.isNotNull(filters.name)) count++;
            if (this.isNotNull(filters.surname)) count++;
            if (this.isNotNull(filters.gender)) count++;
            if (this.isNotNull(filters.nationality_code)) count++;
            if (this.isNotNull(filters.passport_number)) count++;
            if (this.isNotNull(filters.birthdate)) count++;
            if (this.isNotNull(filters.military_status)) count++;

            if (count) {
                let filterButton = '<span class="badge badge-primary ml-1">' + count + '</span>';
                return this.$t('personal_information') + filterButton
            }
            return this.$t('personal_information');
        },
        computeTab5Name() {
            let count = 0;
            let filters = this.datatable.queryParams.filter;

            if (this.isNotNull(filters.payment_status)) count++;
            if (this.isNotNull(filters.scholarship_rate)) count++;

            if (count) {
                let filterButton = '<span class="badge badge-primary ml-1">' + count + '</span>';
                return this.$t('payment_scholarship_information') + filterButton
            }
            return this.$t('payment_scholarship_information');
        },
        computeDocuments() {
            let documents = [];

            if (this.user && this.user.id && this.user.student_number) {
                documents = documentData(this.user)
            }
            return documents;
        }
    },
    data() {
        return {
            studentGroupOptions: [
                {
                    text: 'TR',
                    value: 'TR'
                },
                {
                    text: 'YU',
                    value: 'YU'
                }
            ],
            actions: [
                {
                    text: this.$t('delete_all_students'),
                    permission: 'prepstudent_bulkdelete',
                    callback: () => {
                        this.$swal
                            .fire({
                                text: this.$t('are_you_sure_to_delete'),
                                showCancelButton: true,
                                confirmButtonText: this.$t('yes'),
                                cancelButtonText: this.$t('no')
                            })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    const config = {
                                        params: {
                                            ...this.datatable.queryParams.filter
                                        },
                                        paramsSerializer: (params) => qs.stringify(params, { encode: false })
                                    };
                                    PrepStudentService.bulkDelete(config)
                                        .then(response => {
                                            this.$toast.success(this.$t('api.' + response.data.message));
                                            this.getRows();
                                            this.formClear();
                                        })
                                        .catch(error => {
                                            this.showErrors(error)
                                        });
                                }
                            })
                    }
                }
            ],


            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('view_and_edit'),
                                class: 'ri-search-eye-line',
                                permission: 'studentprogram_show',
                                callback: (row) => {
                                    this.$router.push('/students/' + row.id)
                                }
                            },
                            {
                                text: this.$t('payment_info'),
                                class: 'ri-money-dollar-circle-line',
                                permission: 'customer_customerpayments',
                                callback: (row) => {
                                    if (this.checkPermission('paymentplan_create')) {
                                        this.$router.push('/customers/payments/student/' + row.id);
                                    } else {
                                        this.$router.push('/customers/payments/view/student/' + row.id);
                                    }
                                }
                            },
                            {
                                text: this.$t('course_registration'),
                                class: 'ri-book-line',
                                permission: 'courseregistration_getslot',
                                callback: (row) => {
                                    this.$store.commit("courseRegistration/setStudentNationalityCode", row.nationality_code);
                                    this.$router.push('/course/registration/' + row.id)
                                }
                            },
                            {
                                text: this.$t('edit_slot'),
                                class: 'ri-edit-circle-fill',
                                permission: 'studentslot_update',
                                callback: (row) => {
                                    if (row.student_status !== "101") {
                                        this.$toast.error(this.$t('error_student_status_not_contiune'))
                                        return
                                    }
                                    if (row.active_semester_status === "103" ||
                                        row.active_semester_status === "108" ||
                                        row.active_semester_status === "119" ||
                                        row.active_semester_status === "110" ||
                                        row.active_semester_status === "164") {
                                        this.$toast.error(this.$t('period_status_not_suitable'))
                                        return;
                                    }
                                    this.$router.push('/student/programs/' + row.id + '/slots/edit')
                                }
                            },
                            {
                                text: this.$t('slots'),
                                class: 'ri-list-check',
                                permission: 'studentprogram_studentslot',
                                callback: (row) => {

                                    this.$router.push('/student/programs/' + row.id + '/slots')
                                }
                            },
                            {
                                text: this.$t('documents'),
                                class: 'ri-file-list-line',
                                show: () => {
                                    return this.userDocumentPerm
                                },
                                callback: (row) => {
                                    this.showDocumentModal(row);
                                }
                            },
                            {
                                text: this.$t('student_gno_report'),
                                class: 'ri-bar-chart-2-line',
                                permission: 'studentdocument_gpacontrolexcelexport',
                                callback: ({ id }) => {
                                    StudentDocumentService.downloadGpaStudentExcel(id)
                                        .then(res => {
                                            this._downloadFile(res, this.$t('student_gno_report') + '.xlsx');
                                            this.$toast.success(this.$t('proccess_success'));
                                        })
                                        .catch(err => this.showErrors(err))
                                }
                            },
                            {
                                text: this.$t('gpa_control_report'),
                                class: 'ri-checkbox-circle-line',
                                permission: 'studentdocument_gpacontrol',
                                callback: (row) => {
                                    StudentDocumentService.downloadGpaControl(row.id)
                                        .then(response => {
                                            this._openFile(response)
                                        })
                                        .catch(e => {
                                            this.showErrors(e, null, true)
                                        })
                                }
                            },
                            {
                                text: this.$t('gno_simulation'),
                                class: 'ri-focus-2-line',
                                permission: 'studentcoursesimulation_create',
                                callback: ({ id }) => {
                                    this.$router.push('/student/courses/simulation/' + id)
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-6-line',
                                callback: ({ id }) => {

                                    this.$swal
                                        .fire({
                                            text: this.$t('are_you_sure_to_delete'),
                                            showCancelButton: true,
                                            confirmButtonText: this.$t('yes'),
                                            cancelButtonText: this.$t('no')
                                        })
                                        .then((result) => {
                                            if (result.isConfirmed) {
                                                StudentProgramService.del(id)
                                                    .then(() => {
                                                        this.$toast.success(this.$t('proccess_success'));
                                                        this.filter();
                                                    })
                                                    .catch(e => this.showErrors(e))
                                            }
                                        })
                                },
                                show: () => {
                                    // If user is admin
                                    let user = Base.LocalStorage.get('user')
                                    if (user && user.active_role) return user.active_role.id === 1 || user.active_role.name === 'A';
                                }
                            },

                        ]
                    },
                    {
                        label: this.$t('student_number'),
                        field: 'student_number',
                    },
                    {
                        label: this.$t('national_number'),
                        field: 'national_id',
                    },
                    {
                        label: this.$t('name'),
                        field: 'name',
                    },
                    {
                        label: this.$t('surname'),
                        field: 'surname',
                    },
                    {
                        label: this.$t('faculty'),
                        field: this.getLocaleField('faculty_name'),
                    },
                    {
                        label: this.$t('program'),
                        field: this.getLocaleField('program_name'),
                    },
                    {
                        label: this.$t('registration_type'),
                        field: this.getLocaleField('registration_type_text'),
                    },
                    {
                        label: this.$t('student_status'),
                        field: this.getLocaleField('student_status_text'),
                    },
                    {
                        label: this.$t('scholarship_rate'),
                        field: 'scholarship_rate',
                        formatFn: (value) => {
                            return `%${value}`
                        },
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },




            user: {},
            userDocumentPerm: false,
            postponementForm: {},
            masks: {
                input: "DD-MM-YYYY",
            },
        }
    },
    created() {
        this.$store.dispatch('students/initLayouts');
        if (this.$store.getters['students/getFilters']) {
            this.datatable.queryParams.filter = this.$store.getters['students/getFilters'];
        } else {
            this.filterClear();
        }

    },

    mounted() {
        EventBus.$on('closeDocumentModal', () => {
            this.$refs.showDocumentModal.$refs.commonModal.hide()
        })

        EventBus.$on('openPostponementCertModal', (event) => {
            this.postponementForm.military_dispatch_status = event
            this.$refs.postponementCertModal.$refs.commonModal.show()
        })

        this.$store.dispatch('auth/initUser')
        let user = this.$store.getters['auth/getUser']
        this.userDocumentPerm = user.permissions.filter(c => {
            return c.includes('studentdocument_')
        }).length > 0 ? true : false
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {
                // Tab 1
                faculty_code: null,
                department_code: null,
                program_code: null,
                registration_type: null,
                mode_of_study: null,
                with_thesis: null,
                campus_name: null,
                program_language: null,
                major_type: null,
                prep_status: null,

                // Tab2
                registration_academic_year: null,
                registration_semester: null,
                registration_date_between: null,
                graduation_academic_year: null,
                graduation_semester: null,
                graduation_date_between: null,
                leaving_academic_year: null,
                leaving_semester: null,
                leave_with_consent_reason: null,
                leaving_date_between: null,

                // Tab3
                student_status: null,
                active_semester_status: null,
                number_of_semesters: null,
                yoksis_number_of_semesters: null,
                gno_range_start: null,
                gno_range_end: null,
                irregular: null,

                // Tab4
                student_number: null,
                national_id: null,
                name: null,
                surname: null,
                gender: null,
                nationality_code: null,
                passport_number: null,
                birthdate: null,
                military_status: null,

                // Tab5
                payment_status: null,
                scholarship_rate: null
            }
        },
        filterClearAndGetRow() {
            this.filterClear();
            this.datatable.rows = null
            this.datatable.total = null
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        async postponementAction() {
            const isValid = await this.$refs.postponementForm.validate();
            if (isValid) {
                EventBus.$emit('postponementDataSend', this.postponementForm);
                this.postponementForm = {}
            }
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            let queryParams = JSON.parse(JSON.stringify(this.datatable.queryParams))

            let first = queryParams.filter.gno_range_start
            let end = queryParams.filter.gno_range_end

            if (this.datatable.queryParams.filter) {
                this.$store.dispatch('students/setFilters', this.datatable.queryParams.filter);
            }
            if (first != null && first != "" && end != null && end != "") {
                queryParams.filter.cgpa_between = queryParams.filter.gno_range_start + ',' + queryParams.filter.gno_range_end
            }
            delete queryParams.filter.gno_range_start
            delete queryParams.filter.gno_range_end

            const config = {
                params: {
                    ...queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }

            return StudentProgramService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        closePostponementModal() {
            this.$refs.postponementCertModal.$refs.commonModal.hide()
        },
        // Documents
        showDocumentModal(user) {
            this.user = user
            this.$refs.showDocumentModal.$refs.commonModal.show()
        },
        closedDocumentModal() {
            this.user = {};
        },

        // Exports
        exportExcel() {
            let queryParams = JSON.parse(JSON.stringify(this.datatable.queryParams))

            let first = queryParams.filter.gno_range_start
            let end = queryParams.filter.gno_range_end

            if (this.datatable.queryParams.filter) {
                this.$store.dispatch('students/setFilters', this.datatable.queryParams.filter);
            }
            if (first != null && first != "" && end != null && end != "") {
                queryParams.filter.cgpa_between = queryParams.filter.gno_range_start + ',' + queryParams.filter.gno_range_end
            }

            delete queryParams.filter.gno_range_start
            delete queryParams.filter.gno_range_end

            const config = {
                params: {
                    ...queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }

            let isFilter = false
            const filterValues = Object.values(queryParams.filter)
            filterValues.forEach(filter => {
                if (filter) {
                    isFilter = true
                }
            });

            return StudentProgramService.exportExcel(config, isFilter ? 'arraybuffer' : 'json')
                .then(response => {
                    if (!isFilter) {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                        return
                    }
                    this._downloadFile(response, 'students.xlsx')
                })
        }
    }
}
</script>

